import classNames from "classnames";
import { FC } from "react";
import ApiSectionBubbles from "../../models/api/sections/sectionBubbles.model";
import Bubble from "../Bubble";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import SectionBase from "./SectionBase";
import styles from "./SectionBubbles.module.scss";

const SectionBubbles: FC<ApiSectionBubbles> = ({ title, bubbles }) => {
  return (
    <SectionBase
      title={title}
      titlePosition="padding-left"
      character="3"
      characterPosition="right"
    >
      <Container className={styles.container}>
        <div
          className={classNames(
            styles.decorator,
            styles[`decorator_count-${bubbles.length}`]
          )}
        >
          <ImageCustom
            src="/vector/blue-patch-2.svg"
            width={632}
            height={414}
            alt=""
          />
        </div>
        <div className={styles.bubbles}>
          {bubbles.map((bubble, index) => (
            <Bubble
              {...bubble}
              key={index}
              className={styles[`bubble_${index + 1}`]}
            />
          ))}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionBubbles.defaultProps = {};

export default SectionBubbles;
