import classNames from "classnames";
import { FC } from "react";
import ApiBubble from "../models/api/utils/bubble.model";
import { getVariant } from "../utils/color.utils";
import styles from "./Bubble.module.scss";
import Typo from "./Typo";

export interface IBubbleProps extends ApiBubble {
  className: string;
}

const Bubble: FC<IBubbleProps> = (props) => {
  return (
    <div
      className={classNames(
        styles.container,
        styles[`color_${getVariant(props.color)}`],
        props.className
      )}
    >
      <div className={styles.content}>
        <Typo variant="h2" className={styles.title}>
          {props.title}
        </Typo>
        <Typo color="dark">{props.text}</Typo>
      </div>
    </div>
  );
};

Bubble.defaultProps = {};

export default Bubble;
