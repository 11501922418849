import { FC } from "react";
import ApiButton from "../models/api/utils/button.model";
import Button, { IButtonProps } from "./Button";

export interface IButtonCmsProps
  extends ApiButton,
    Pick<IButtonProps, "color"> {
  className?: string;
}

const ButtonCms: FC<IButtonCmsProps> = ({
  color,
  className,
  label,
  url,
  isExternal,
}) => {
  return (
    <Button
      color={color}
      className={className}
      text={label}
      href={url}
      isExternal={isExternal}
    />
  );
};

ButtonCms.defaultProps = {};

export default ButtonCms;
