import classNames from "classnames";
import { FC } from "react";
import { getMediaUrl } from "../utils/api.utils";
import styles from "./EditoAuthor.module.scss";
import ImageCustom from "./ImageCustom";
import Typo from "./Typo";

export interface EditoAuthorProps {
  name: string;
  job: string;
  reverse: boolean;
  imageSrc?: string;
  className?: string;
}

const EditoAuthor: FC<EditoAuthorProps> = ({
  name,
  job,
  reverse,
  imageSrc,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.author,
        reverse && styles.author_reverse,
        className
      )}
    >
      <div className={styles.imageWrap}>
        <ImageCustom
          src={imageSrc ? getMediaUrl(imageSrc) : "/placeholder.jpg"}
          width={200}
          height={200}
          alt={name}
          objectFit="cover"
          className={styles.image}
        />
      </div>
      <div className={styles.info}>
        <Typo
          variant="h5"
          component="span"
          color="primary"
          className={styles.name}
        >
          {name}
        </Typo>
        <Typo
          variant="body"
          component="span"
          color="primary"
          className={styles.job}
        >
          {job}
        </Typo>
      </div>
    </div>
  );
};

EditoAuthor.defaultProps = {};

export default EditoAuthor;
