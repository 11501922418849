import { FC } from "react";
import { useNavigationContext } from "../../context/NavigationContext";
import { ApiSection } from "../../models/api/section.model";
import { ITemplatePageProps } from "../../pages/[slug]";
import { slugify } from "../../utils/string.utils";
import PageLayout from "../PageLayout";
import SectionBubbles from "../sections/SectionBubbles";
import SectionBulletPoints from "../sections/SectionBulletPoints";
import SectionCardList from "../sections/SectionCardList";
import SectionDownload from "../sections/SectionDownload";
import SectionEdito from "../sections/SectionEdito";
import SectionFAQ from "../sections/SectionFAQ";
import SectionHero from "../sections/SectionHero";
import SectionImage from "../sections/SectionImage";
import SectionKeyNumbers from "../sections/SectionKeyNumbers";
import SectionKeyNumbersAlt from "../sections/SectionKeyNumbersAlt";
import SectionMap from "../sections/SectionMap";
import SectionPictos from "../sections/SectionPictos";
import SectionPoles from "../sections/SectionPoles";
import SectionProducts from "../sections/SectionProducts";
import SectionService from "../sections/SectionService";
import SectionText from "../sections/SectionText";
import SectionTextAndImage from "../sections/SectionTextAndImage";
import SectionTiles from "../sections/SectionTiles";
import SectionTimeline from "../sections/SectionTimeline";
import SectionYoutubeVideo from "../sections/SectionYoutubeVideo";
import Spacer from "../Spacer";

const TemplatePageComponent: FC<ITemplatePageProps> = ({
  content,
  menu,
  slug,
}) => {
  const sections = content.attributes.body;

  const { refsChapters } = useNavigationContext();

  function renderSection(section: ApiSection, index: number) {
    switch (section.__component) {
      case "section.text":
        return <SectionText {...section} />;
      case "section.text-and-image":
        return <SectionTextAndImage {...section} />;
      case "section.hero":
        return <SectionHero {...section} />;
      case "section.edito":
        return <SectionEdito {...section} />;
      case "section.tiles":
        return <SectionTiles {...section} />;
      case "section.faq":
        return <SectionFAQ data={section} />;
      case "section.bubbles":
        return <SectionBubbles {...section} />;
      case "section.spacer":
        return <Spacer {...section} />;
      case "section.card-list":
        return <SectionCardList {...section} />;
      case "section.key-numbers":
        return <SectionKeyNumbers {...section} />;
      case "section.key-numbers-alt":
        return <SectionKeyNumbersAlt {...section} />;
      case "section.pictos":
        return <SectionPictos {...section} />;
      case "section.timeline":
        return <SectionTimeline {...section} />;
      case "section.bullet-points":
        return <SectionBulletPoints {...section} />;
      case "section.youtube-video":
        return <SectionYoutubeVideo {...section} />;
      case "section.products":
        return <SectionProducts {...section} />;
      case "section.service":
        return <SectionService {...section} />;
      case "section.poles":
        return <SectionPoles {...section} />;
      case "section.download":
        return <SectionDownload {...section} />;
      case "section.image":
        return <SectionImage {...section} />;
      case "section.map":
      case "section.map-free":
        return <SectionMap {...section} />;
    }
  }
  return (
    <PageLayout menu={menu} slug={slug}>
      {sections.map((section: ApiSection, index: number) => (
        <div
          id={
            section.__component === "section.spacer"
              ? `spacer-${index}`
              : section.title
              ? slugify(section.title as string)
              : `section-${index}`
          }
          key={index}
          ref={refsChapters[index]}
        >
          {renderSection(section, index)}
        </div>
      ))}
    </PageLayout>
  );
};

TemplatePageComponent.defaultProps = {};

export default TemplatePageComponent;
