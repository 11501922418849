import classNames from "classnames";
import { FC } from "react";
import ApiPicto from "../models/api/utils/picto.model";
import { getMediaUrl } from "../utils/api.utils";
import ImageCustom from "./ImageCustom";
import styles from "./Picto.module.scss";
import Typo from "./Typo";

export interface PictoProps extends ApiPicto {
  size: "medium" | "large";
}

const Picto: FC<PictoProps> = ({ size, image, label }) => {
  const imageSize = size === "medium" ? 80 : 100;
  return (
    <div
      className={classNames(
        styles.container,
        styles[`container_image-${size}`]
      )}
    >
      <div className={styles.image}>
        <ImageCustom
          src={getMediaUrl(image.data?.attributes?.url)}
          width={imageSize}
          height={imageSize}
          alt={image.data?.attributes?.alternativeText}
          objectFit="contain"
        />
      </div>
      <div className={styles.labelWrap}>
        <Typo
          variant={size === "medium" ? "button" : "h5"}
          color="secondary"
          className={styles.label}
        >
          {label}
        </Typo>
      </div>
    </div>
  );
};

Picto.defaultProps = {};

export default Picto;
