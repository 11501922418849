import { FC } from "react";
import { FaArrowRight } from "react-icons/fa";
import ApiSectionBulletPoints from "../../models/api/sections/sectionBulletPoints.model";
import Container from "../Container";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionBulletPoints.module.scss";

const SectionBulletPoints: FC<ApiSectionBulletPoints> = ({
  title,
  bulletPoints,
}) => {
  return (
    <SectionBase title={title} titlePosition="center">
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          {bulletPoints?.map((bulletPoint, index) => (
            <div key={index} className={styles.printWrap}>
              <div className={styles.bulletPoint}>
                <div className={styles.iconWrapper}>
                  <FaArrowRight className={styles.icon} />
                </div>
                <Typo color="dark">
                  <b>{bulletPoint.bulletPoint}</b>
                </Typo>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </SectionBase>
  );
};

export default SectionBulletPoints;
